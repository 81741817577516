
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'AppBody',
  props: {
    msg: String
  },
  data () {
    return {
      currentTime: 0,
      timer: 0
    }
  },
  created () {
    this.updateTime()
    this.timer = setInterval(this.updateTime, 1000)
  },
  methods: {
    updateTime () {
      this.currentTime = Math.round(+new Date() / 1000)
    },
    cancelAutoUpdate () {
      clearInterval(this.timer)
    }
  },
  beforeUnmount () {
    this.cancelAutoUpdate()
  }
})
